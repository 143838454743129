figure.container {
	position: fixed;
	top: 6rem;
	width: 80vw;
	max-width: 78rem;
	left: calc((100vw - 78rem) / 2);
	display: flex;
	flex-direction: row-reverse;
}

figure.container button {
	padding-right: 0;
	border-radius: 0;
	transform: scale(1);
	transform-origin: 50% 50%;
	transition: transform var(--transition-duration) var(--transition-timing-function);
}

figure.container button:hover {
	transform: scale(.8);
}

figure.container:global(.error) button,
figure.container:global(.error) button:hover,
figure.container:global(.error) a,
figure.container:global(.error) a:hover {
	color: var(--text-on-error)
}

figure.container:global(.success) button,
figure.container:global(.success) button:hover,
figure.container:global(.success) a,
figure.container:global(.success) a:hover {
	color: var(--text-on-success)
}

figure.container figcaption {
	width: 100%;
}

@media screen and (max-width: 1560px) {
	figure.container {
		left: 10vw;
	}
}

@media screen and (max-width: 500px) {
	figure.container {
		width: calc(100vw - 2rem);
		left: 1rem;
	}
}