ul {
	padding: 0;
}

li {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--text-on-primary-light);
}

li p {
	margin: 0.5rem 0;
}
