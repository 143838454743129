.container input {
	text-transform: uppercase;
}

.errorContainer {
	position: relative;
	width: 100%;
	height: 6rem;
	pointer-events: none;
}

.errorContainer :global(.message.error) {
	position: absolute;
	margin-top: 1rem;
	min-width: 100%;
}

.container input:focus + .errorContainer {
	opacity: 0;
}
