body {
	background-color: var(--fill-primary);
	color: var(--text-on-primary-dark);
}

p, span, details, summary {
	font-family: var(--font-family-body);
	font-size: 1.125rem;
	font-weight: 500;
	letter-spacing: 0.25px;
}

h1 {
	color: var(--text-on-primary-dark);
	font-family: var(--font-family-heading);
	font-size: 3.75rem;
	font-weight: 400;
	letter-spacing: -1.28px;
	line-height: 4.25rem;
}

h2 {
	color: var(--text-on-primary-dark);
	font-family: var(--font-family-body);
	font-size: 2.5rem;
	font-weight: 700;
	letter-spacing: 0.55px;
}

h3 {
	color: var(--text-on-primary-dark);
	font-family: var(--font-family-body);
	font-size: 1.5rem;
	font-weight: 700;
	letter-spacing: 0.33px;
}

h4 {
	color: var(--text-on-primary-dark);
	font-family: var(--font-family-body);
	font-size: 1.25rem;
	font-weight: 700;
	letter-spacing: 0.33px;
}

p strong {
	font-weight: 700;
}

h1 strong, h2 strong, h3 strong {
	color: var(--fill-secondary);
}

input:not([type='button'], [type='submit'], [type='reset']), select {
	color: var(--text-on-neutral-dark);
	font-family: var(--font-family-body);
	font-size: 1.125rem;
	font-weight: 600;
	letter-spacing: 0.25px;
}

input::placeholder {
	color: var(--text-on-neutral-light);
	transition: color var(--transition-duration) var(--transition-timing-function);
}

:where(label, a, button, input[type='button'], input[type='submit'], input[type='reset']) {
	font-family: var(--font-family-body);
	font-weight: 700;
	font-size: 1rem;
	letter-spacing: 0.22px;
}

label {
	color: var(--text-on-primary-medium);
}
