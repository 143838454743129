details {
	background-color: var(--fill-neutral);
	color: var(--text-on-neutral-medium);
	padding: 1rem 2rem;
	border-bottom: 1px solid var(--text-on-neutral-light);
	white-space: pre-line;
	transition: background-color var(--transition-duration) var(--transition-timing-function), height var(--transition-duration) var(--transition-timing-function);
}

details:first-of-type {
	border-radius: 0.5rem 0.5rem 0 0;
}

details:last-of-type {
	border-radius: 0 0 0.5rem 0.5rem;
	border-bottom: none;
}

details summary::-webkit-details-marker {
	display: none;
}

details summary {
	display: flex;
	color: var(--text-on-neutral-medium);
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	transition: color var(--transition-duration) var(--transition-timing-function);
}

details summary::after {
	display: block;
	content: "chevron down";
	font-family: var(--font-family-icon);
	transform: rotate(-90deg);
	margin-left: 1rem;
	font-size: 1rem;
	min-width: 1rem;
	transition:
		color var(--transition-duration) var(--transition-timing-function),
		transform var(--transition-duration) var(--transition-timing-function);
}

details[open] {
	background-color: var(--background-behind-secondary);
}

details[open] summary {
	color: var(--fill-primary);
	margin-bottom: 1rem;
}

details[open] summary::after {
	transform: rotate(0deg);
}

details a:not([type='button']),
details a:not([type='button']):hover {
	color: var(--fill-primary);
}
