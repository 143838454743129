section.section {
	display: flex;
	flex-direction: column;
}

.input div {
	grid-auto-flow: initial;
	grid-template-columns: repeat(7, auto);
}

.checkbox {
	margin: -6rem 0 3rem;
	transition: margin var(--transition-duration) var(--transition-timing-function);
}

.checkbox.hidden {
	margin: 0;
}

.image {
	align-self: center;
	width: 100%;
	max-width: 280px;
	pointer-events: none;
}
