.location-redirect-modal {
	position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);

	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	transform-style: preserve-3d;

	max-width: 550px;

    border-radius: 10px;
	border-style: solid;

	padding: 20px;
	padding-bottom: 0px;

	background-image: url('https://ikhighfiveprddatast.blob.core.windows.net/files/ikhighfive/locationRedirectModalBackground.png');
	background-size: cover;
	background-position: center;

	text-align: center;
}

.location-redirect-modal::after {
	content: "";

	position: absolute;
	transform: translateZ(-1px);

	width: 100vw;
    height: 102vh;

	background-color: #0000ff26;
	backdrop-filter: grayscale(1) blur(5px);
}

.location-redirect-modal h2 {
	margin: 0;

	line-height: 45px;
}

.actions-wrapper {
	display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;
}

.actions-wrapper button {
	width: 40%;
}

.location-redirect-modal .never-ask-again {
	margin-top: 15px;
	border-style: none;

	text-decoration: underline;
}

@media screen and (max-width: 1024px) {
	.location-redirect-modal {
		width: 90%;
		max-width: 500px;
	}

	.actions-wrapper button {
		padding: 0;
	}
}