:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button']) {
	border-radius: 20px;
	background-color: var(--text-on-primary-dark);
	color: var(--fill-primary);
	height: 2.5rem;
	cursor: pointer;
	border: none;
	padding: 0 3rem;
	transition: background-color var(--transition-duration) var(--transition-timing-function);
	text-align: center;
}

:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button']):hover {
	background-color: var(--text-on-primary-medium);
}

:where(button, input[type='submit'], input[type='button'], input[type='reset']):disabled, :where(a[type='button'])[aria-disabled='true'] {
	background-color: var(--text-on-primary-light);
	cursor: not-allowed;
}

@supports selector(:has(i)) {
	:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button']):has(i) {
		padding: 0 1rem;
	}
}

@supports not selector(:has(i)) {
	:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button']).icon {
		padding: 0 1rem;
	}
}

a[type='button'] {
	display: inline-block;
	text-decoration: none;
	line-height: 2.5rem;
	-webkit-appearance: none;
}

a:not([type='button']) {
	color: var(--text-on-primary-medium);
	text-decoration: none;
	transition: color var(--transition-duration) var(--transition-timing-function);
}

a:not([type='button']):hover {
	color: var(--text-on-primary-dark);
}

a:not([type='button'])[aria-disabled='true'] {
	color: var(--text-on-primary-light);
	cursor: not-allowed;
}
