:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button'], span).step {
	height: inherit;
	padding: 0;
	margin-bottom: 1rem;
	background-color: transparent;
	border-radius: 0%;
	color: var(--text-on-primary-light);
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1rem auto 1rem;
	gap: 0.5rem;
	align-items: center;
	text-align: left;
	width: 100%;
	border: 0.2rem solid transparent;
	transition: color var(--transition-duration) var(--transition-timing-function);
	line-height: 2.5rem;
}

a[type='button'].step {
	display: grid;
}

:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button'], span).step::after {
	content: "";
	display: block;
	width: 0.75rem;
	height: 0.75rem;
	background-color: var(--text-on-primary-light);
	border-radius: 100%;
	justify-self: flex-end;
	transition:
		background-color var(--transition-duration) var(--transition-timing-function),
		transform var(--transition-duration) var(--transition-timing-function),
		box-shadow var(--transition-duration) var(--transition-timing-function);
}

:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button'], span).step.done::after {
	background-color: var(--fill-success);
	box-shadow: 0px 0px 6px var(--fill-success);
}

:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button'], span).step.active {
	color: var(--text-on-primary-dark);
	font-weight: bold;
}

:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button'], span).step.active::after {
	background-color: transparent;
	border: 0.2rem solid var(--text-on-primary-dark);
	transform: scale(1.5);
}

:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button'], span).step:not(.active):hover {
	color: var(--text-on-primary-medium);
}

:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button'], span).step:not(.done, .active):hover::after {
	background-color: transparent;
	border: 0.2rem solid var(--text-on-primary-medium);
	transform: scale(1.5);
}
