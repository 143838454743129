div.outer {
	position: absolute;
	margin: -3rem 0 0 0.5rem;
	overflow: hidden;
	max-width: calc(100% - 1rem);
}

div.inner {
	float: right;
	display: flex;
	align-items: center;
	height: 3rem;
}

/* Replace with div.inner { gap: 0.5rem } once flexbox gap is supported. */
div.inner .pill:not(:last-child) {
	margin-right: 0.5rem;
}

div.pill {
	height: 1.8125rem;
	border-radius: 0.5rem;
	background-color: var(--fill-secondary-faded);
	border: 1px solid var(--fill-secondary);
	color: var(--fill-primary);
	padding: 0 0.5rem;
	white-space: nowrap;
	display: flex;
	align-items: center;
	transition:
		background-color var(--transition-duration) var(--transition-timing-function),
		border var(--transition-duration) var(--transition-timing-function),
		color var(--transition-duration) var(--transition-timing-function);
}

/* Replace with div.pill { gap: 0.5rem } once flexbox gap is supported. */
div.pill button {
	margin-left: 0.5rem;
}

button.button {
	background-color: var(--fill-primary);
	color: var(--fill-secondary);
	width: 1rem;
	height: 1rem;
	padding: 0 !important;
	transition:
		background-color var(--transition-duration) var(--transition-timing-function),
		color var(--transition-duration) var(--transition-timing-function);
}

button.button i {
	font-size: 0.5rem;
	display: block;
	height: 0.5rem;
}

@supports selector(:has(input)) {
	label:has(input:not([type='button'], [type='reset'], [type='submit'])):has(div.outer) {
		position: relative;
		display: inline-block;
	}

	label:has(input:not([type='button'], [type='reset'], [type='submit']):focus):has(div.outer) div.outer {
		display: none;
	}

	label:has(input:not([type='button'], [type='reset'], [type='submit'])):has(div.outer) input:not(:focus):not(:placeholder-shown),
	label:has(input:not([type='button'], [type='reset'], [type='submit'])):has(div.outer) input:not(:focus):not(:placeholder-shown):autofill {
		color: transparent;
		-webkit-text-fill-color: transparent;
	}

	label:has(input:not([type='button'], [type='reset'], [type='submit'])):has(div.outer) input:placeholder-shown {
		caret-color: var(--text-on-neutral-dark);
	}

	label:has(input:not([type='button'], [type='reset'], [type='submit'])[aria-invalid='true']:not(:focus)) div.pill {
		border: 1px solid var(--fill-error);
		color: var(--background-behind-error);
		background-color: var(--text-on-error);
	}

	label:has(input:not([type='button'], [type='reset'], [type='submit'])[aria-invalid='true']:not(:focus)) div.pill button.button {
		background-color: var(--background-behind-error);
		color: var(--text-on-error);
	}
}

@supports not selector(:has(input)) {
	label:global(.pills) {
		position: relative;
		display: inline-block;
	}

	label:global(.pills) input:focus + div.outer {
		display: none;
	}

	label:global(.pills) input:not(:focus),
	label:global(.pills) input:not(:focus):-webkit-autofill {
		color: transparent;
		-webkit-text-fill-color: transparent;
	}

	label:global(.pills) input:placeholder-shown {
		caret-color: var(--text-on-neutral-dark);
	}

	label[aria-invalid='true']:not(:focus-within) div.pill {
		border: 1px solid var(--fill-error);
		color: var(--background-behind-error);
		background-color: var(--text-on-error);
	}

	label[aria-invalid='true']:not(:focus-within) div.pill button.button {
		background-color: var(--background-behind-error);
		color: var(--text-on-error);
	}
}
