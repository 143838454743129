figure.message {
	opacity: 0;
	transition: opacity var(--transition-duration) var(--transition-timing-function);
}

figure.message.error {
	opacity: 1;
	font-family: var(--font-family-body);
	color: var(--text-on-error);
	border: 1px solid var(--text-on-error);
	border-radius: 0.5rem;
	background-color: var(--background-behind-error);
	padding: 0.75rem 1rem;
	margin: 0;
	min-width: 23rem;
}

figure.message.success {
	opacity: 1;
	font-family: var(--font-family-body);
	color: var(--text-on-success);
	border: 1px solid var(--text-on-success);
	border-radius: 0.5rem;
	background-color: var(--background-behind-success);
	padding: 0.75rem 1rem;
	margin: 0;
	min-width: 23rem;
}
