.page {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.video {
	margin-bottom: 1rem;
	width: 100%;
}
