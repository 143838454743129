.container p:first-of-type {
	white-space: nowrap;
}

.container a:not([type='button']) {
	text-align: right;
}

.container a:not([type='button']).light {
	color: var(--text-on-primary-light);
}
