.on-neutral {
	color: var(--text-on-neutral-dark);
}

p.medium {
	color: var(--text-on-primary-medium);
}

p.light {
	color: var(--text-on-primary-light);
}

p.medium.on-neutral {
	color: var(--text-on-neutral-medium);
}

p.light.on-neutral {
	color: var(--text-on-neutral-light);
}

h1.on-neutral,
h2.on-neutral,
h3.on-neutral {
	color: var(--fill-primary);
}

label.on-neutral {
	color: var(--text-on-neutral-medium);
}

p.error {
	color: var(--text-on-error);
}
