.content h2 {
	font-family: var(--font-family-heading);
}

.content {
	display: flex;
	flex-direction: column;
}

.content > div {
	display: flex;
	flex-direction: row;
	flex-basis: 50%;
	margin-top: 50px;
	margin-bottom: 50px;
	position: relative;
	padding-left: 15%;
	padding-right: 15%;
}

.content img {
	width: 50%;
}

.mission div {
	margin: auto;
	text-align: center;
	transform: rotate(2deg);
}

.negative:before {
	content: "";
	position: absolute;
	float: left;
	display: block;
	top: -100px;
	height: 100px;
	width: 100vw;
	background: hsla(0, 0%, 100%, 0.5);
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 70 500 60' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='500' height='500' style='stroke: none; fill: %23ECF4FF;' /%3E%3Cpath d='M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z' style='stroke: none; fill: %231478FF;'%3E%3C/path%3E%3C/svg%3E");
	background-size: 100% 100%;
	left: 0;
}

.negative:after {
	content: "";
	position: absolute;
	float: left;
	display: block;
	bottom: -100px;
	height: 100px;
	width: 100vw;
	background: hsla(0, 0%, 100%, 0.5);
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 70 500 60' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='500' height='500' style='stroke: none; fill: %231478FF;' /%3E%3Cpath d='M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z' style='stroke: none; fill: %23ECF4FF;'%3E%3C/path%3E%3C/svg%3E");
	background-size: 100% 100%;
	left: 0;
}

.how div {
	transform: rotate(-1.82436546deg);
	margin: auto;
	text-align: center;
}

.how img {
	margin: auto;
    margin-left: 25px;
}

.content .platform {
	margin-top: 0px;
	margin-bottom: 180px;
}

.platform div {
	transform: rotate(2deg);
	margin: auto;
	text-align: center;
}

.platform img {
	transform: rotate(-1.8deg);
}

.content .rewards {
	margin-bottom: 150px;
}

.rewards img {
	width: 100%;
}

.rewards div {
	position: relative;
}

.rewards div:nth-child(2n) {
	transform: translateY(-20%);
}

.rewards div p {
	position: absolute;
	background-color: #6112AF;
	padding: 10px;
	border-radius: 10px;
	font-family: var(--font-family-heading);
	font-size: 2rem;
	left: 50%;
	bottom: -30%;
	transform: translateX(-50%);
	min-width: 260px;
	text-align: center;
	z-index: 100;
}

.content .pitch {
	display: block;
	text-align: center;
	position: relative;
}

.pitch img {
	width: 25%;
}

.pitch p {
	font-size: 1.9rem;
}

.numbers {
	text-align: center;
}

.numbers h1 {
	font-size: 6rem;
}

.numbers div {
	transform: rotate(-1.8deg);
	margin: auto;
}

.numbers img {
	transform: rotate(2.9014912deg);
	margin-right: 25px;
	max-height: 1500px;
	width: auto;
	max-width: 400px;
    margin-bottom: 60px;
}

.numbers button {
	font-family: var(--font-family-heading);
	font-size: 2rem;
	line-height: 35px;
	border-radius: 45px;
	width: 300px;
    height: 90px;
}

.clickable {
	position: relative;
}

.clickable::after {
	content: "";
	background-image: url(../../assets/images/clickable.webp);
	background-size: cover;
	width: 50px;
	height: 50px;
	position: absolute;
	right: -25px;
    bottom: -25px;
}

.content .map {
	gap: 50px;
}

.map h2 {
	margin-bottom: 0;
	transform: rotate(2.9014912deg);
}

.map img {
	height: 100%;
	width: auto;
	margin: auto;
	transform: rotate(-2.9014912deg);
	border-radius: 25px;
}

.map div img {
	transform: scaleX(-1);
	height: auto;
	width: 100%;
	max-width: 500px;
}

.content .footer {
	gap: 200px;
}

.footer img {
	height: auto;
	width: 100%;
	transform: rotate(-2.9014912deg);
	margin-bottom: 25px;
	margin-left: 25px;
	margin-top: 25px;
	max-width: 400px;
}

.footer div {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer form {
	width: 100%;
}

.footer textarea {
	min-height: 100px;
}

.footer button[type="submit"] {
	margin-top: 10px;
	width: 100%;
}

.negative {
	background-color: #ECF4FF;
}

.negative h1, .negative h2, .negative p {
	color: var(--fill-primary);
}

.footer .socials {
	display: flex;
	justify-content: center;
	flex-direction: row;
}

.socials > a {
	width: 50px;
	height: 50px;
	display: block;
	font-size: 0px;
	background-size: cover;
	margin: 10px;
}

.tiktok {
	background-image: url(../../assets/images/tiktok.png);
}

.tiktok::after {
	width: 45px;
	height: 45px;
}

.instagram {
	background-image: url(../../assets/images/instagram.svg);
}

.facebook {
	background-image: url(../../assets/images/facebook.svg);
}

@media screen and (max-width: 800px) {
	.content h1 {
		font-size: 3rem;
	}

	.content h2 {
		font-size: 2rem;
	}

	.content > div {
		display: flex;
		flex-direction: column;
		padding: 0;
	}

	.content .mission img {
		width: 100%;
	}
	
	.content .rewards {
		flex-direction: row;
		margin-bottom: 100px;
	}

	.content .rewards div {
		transform: none;
	}

	.content .rewards img {
		width: 100%;
	}

	.content .rewards p {
		display: none;
	}

	.content .pitch img {
		width: 50%;
	}

	.content .numbers {
		flex-direction: column-reverse;
		margin-bottom: 100px;
	}

	.content .numbers h1 {
		font-size: 6rem;
		margin-top: 25px;
	}

	.content .numbers img {
		margin-top: 25px;
	}
	
	.content .map {
		text-align: center;
	}

	.content .map img {
		margin: 0;
   		width: 100%;
		overflow: hidden;
		transform: rotate(0);
		margin-top: 10px;
	}

	.content .map div {
		display: flex;
		flex-direction: column;
	}

	.content .map h2 {
		margin: 0;
	}

	.content .map div img {
		margin: auto;
   		width: 35%;
	}

	.content img {
		width: 75%;
		margin: auto;
	}	

	.content .footer {
		flex-direction: column-reverse;
		gap: 50px;
	}

	.content .footer>div {
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.content .footer .overdrive {
		width: 100%;
		max-width: 400px;
	}

	.content .footer .overdrive img {
		height: auto;
		width: 100%;
		max-width: 400px;
	}

	.content .platform {
		margin-top: 0px;
		margin-bottom: 0px;
	}

	.content .platform img {
		margin-top: 50px;
	}
}

@media screen and (max-width: 1100px) {
	.content > div {
		padding-left: 0;
		padding-right: 0;
	}
}