figure {
	margin: 1rem 0;
}

.layout section {
	display: grid;
	grid-template-columns: repeat(4, 25%);
	gap: 1rem;
	width: 100%;
}

.layout section h2 {
	grid-column: span 4;
	display: flex;
	align-items: flex-end;
}

.layout section h2 p {
	margin: 0 .5rem .5rem;;
}

.neutral {
	background-color: var(--fill-neutral);
	border-radius: 0.5rem;
	padding: .5rem;
	color: var(--text-on-neutral-dark);
}
