.page {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	height: 100%;
}

.page section {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
}

.page h1 {
	grid-column: span 6;
	transform: rotate(-7deg);
	margin: 4rem 4rem 0 4rem;
	pointer-events: none;
}

.page .tagForm {
	grid-column: 2 / span 4;
	margin-top: -5rem;
}

.five {
	transform: scale(1.4);
	pointer-events: none;
}

@media screen and (max-width: 1280px) {
	.page h1 {
		margin: 4rem 0;
	}

	.page .tagForm {
		grid-column: span 6;
	}
}

@media screen and (max-width: 1112px) {
	.page img {
		max-width: 151%;
	}
}

@media screen and (max-width: 1024px) {
	.page .tagForm {
		margin-top: -3rem;
	}
}

@media screen and (max-width: 800px) {
	body::before {
		display: none;
	}

	.page img {
		display: none;
	}

	.page section {
		grid-column: span 2;
	}

	.page h1 {
		text-align: center;
	}

	.page .tagForm {
		grid-column: 2 / span 4;
		margin-top: 0rem;
	}
}

@media screen and (max-width: 500px) {
	.page .tagForm {
		grid-column: span 6;
	}
}
