.container {
	display: grid;
	height: fit-content;
	grid-template-rows: [input-start] auto [input-end register-start] auto [register-end login-start] auto [login-end];
	gap: 1rem;
}

.container button {
	grid-row: register-start / register-end;
}

.container a {
	grid-row: login-start / login-end
}

.tagId input {
	text-transform: uppercase;
}

.tagId input::placeholder {
	text-transform: none;
}
