.page {
	--header-height: 10.8125rem;
	height: calc(100vh - var(--header-height));
	display: flex;
	justify-content: space-between;
	height: 100%;
}

.five {
	pointer-events: none;
	height: 842px;
}

.container {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: 10rem;
}

.container h1 {
	max-width: 29rem;
	margin-bottom: 8rem;
	text-align: center;
}

.buttons {
	display: flex;
	flex-direction: column;
    align-items: center;
	width: 100%;
}

.buttons a {
	width: 18.75rem;
	margin: 10px;
	width: 100%;
}

@media screen and (max-width: 1280px) {
	.container h1 {
		text-align: center;
	}

	.buttons {
		grid-auto-flow: row;
		justify-content: center;
	}
}

@media screen and (max-width: 1024px) {
	.page {
		--header-height: 5.8125rem;
	}
}

@media screen and (max-width: 800px) {
	body::before {
		display: none;
	}

	.five {
		display: none;
	}

	.container {
		width: 100%;
	}

	.container h1 {
		text-align: center;
	}
}

@media screen and (max-width: 500px) {
	.container {
		padding-top: 8rem;
	}

	.container h1 {
		text-align: left;
		margin-bottom: 3rem;
	}

	.buttons {
		width: 100%;
	}

	.buttons a {
		width: 100%;
	}
}