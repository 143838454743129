.header {
	text-align: center;
}

.header h1 {
	color: var(--fill-secondary);
	font-size: 6rem;
}

.cta {
	text-align: center;
}

.cta h2 {
	margin-bottom: 0px;
}

.cta h3 {
	margin-top: 0px;
	font-size: 2.5rem;
}

.content {
	margin-bottom: 10px;
}

.content h4 {
	margin-bottom: 10px;
}

.content p {
	margin-top: 0px;
}

.vibe { 
	margin-top: 10px;
}

.mailto {
	font-style: italic;
}

ul li {
	border-bottom: none;
}

@media screen and (max-width: 500px) {
	.header h1 {
		font-size: 5rem;
	}

	.cta h2 {
		font-size: 2rem;
	}

	button {
		height: 50px;
	}
}