.cookie-modal {
    position: absolute;
	right: 10px;
	bottom: 0;

	background-color: RGB(12, 96, 219);

	width: 400px;

	border-color: var(--fill-secondary);
    border-width: 4px;
    border-style: dashed;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
}

.cookie-modal img {
	background-color: rgba(255,255,255, 0.4);
}


.text {
	padding: 10px;

	display: flex;
	flex-direction: column;

	text-align: center;
}

.text button {
	margin-top: 10px;
	margin-bottom: 10px;
}

@media screen and (max-width: 500px) {
	.cookie-modal {
		position: sticky;
		bottom: 0;
		left: 0;
		right: 0;

		width: 100vw;
	}

	.cookie-modal .text {
		margin: 0;
	}

	.cookie-modal img {
		display: none;
	}

	.cookie-modal p {
		margin: 0;
	}

	.cookie-modal button {
		margin-top: 4px;
		margin-bottom: 4px;
	}
}