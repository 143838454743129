:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button']).secondary {
	background-color: transparent;
	border: 1px solid var(--text-on-primary-dark);
	color: var(--text-on-primary-dark);
	transition:
		color var(--transition-duration) var(--transition-timing-function),
		border var(--transition-duration) var(--transition-timing-function),
		background-color var(--transition-duration) var(--transition-timing-function);
}

:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button']).secondary:hover {
	background-color: var(--text-on-primary-light);
	border: 1px solid var(--text-on-primary-medium);
	color: var(--text-on-primary-medium);
}

:where(button, input[type='submit'], input[type='button'], input[type='reset'], a[type='button']).secondary:disabled {
	background-color: transparent;
	border: 1px solid var(--text-on-primary-light);
	color: var(--text-on-primary-light);
}

:where(button, input[type='submit'], input[type='button'], input[type='reset']).text {
	color: var(--text-on-primary-medium);
	height: auto;
	background-color: transparent;
	text-decoration: none;
	padding: 0;
	transition: color var(--transition-duration) var(--transition-timing-function);
}

:where(button, input[type='submit'], input[type='button'], input[type='reset']).text:hover {
	color: var(--text-on-primary-dark);
}

:where(button, input[type='submit'], input[type='button'], input[type='reset']).text:disabled {
	color: var(--text-on-primary-light);
	cursor: not-allowed;
}

a[type='button'].tab {
	background-color: var(--text-on-primary-medium);
	color: var(--text-on-neutral-light);
	border-radius: 0.5rem;
	padding: 0 1.5rem;
	transition:
		background-color var(--transition-duration) var(--transition-timing-function),
		color var(--transition-duration) var(--transition-timing-function);
}

a[type='button'].tab.active,
a[type='button'].tab:hover {
	background-color: var(--fill-neutral);
	color: var(--text-on-neutral-dark);
	
}