.hamburger {
	cursor: pointer;
	display: none;
	z-index: var(--header-index);
	padding: 0 !important;
}

.navigation {
	position: absolute;
	width: 100vw;
	height: 100vh;
	background-color: var(--fill-primary);
	top: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	left: 0;
	transform: scale(0);
	transition: transform var(--transition-duration) var(--transition-timing-function);
	z-index: var(--between-content-and-header-index);
}


/* Replace with .navigation { gap: 2.5rem } once flexbox gap is supported. */
.navigation a:not(:last-child) {
	margin-bottom: 2.5rem;
}

.navigation a {
	width: fit-content;
	display: block !important;
	text-align: center;
}

.navigation a:first-child {
	margin-top: 30vh;
}

.navigation.isOpen {
	transform: scale(1);
}

@supports selector(:has(nav.navigation)) {
	header:has(nav.navigation) + main {
		transform: scale(1);
		opacity: 1;
		transition:
			transform var(--transition-duration) var(--transition-timing-function),
			opacity var(--transition-duration) var(--transition-timing-function);
	}

	header:has(nav.navigation.isOpen) + main {
		transform: scale(0.8);
		opacity: 0;
	}
}

@media screen and (max-width: 1024px) {
	.hamburger {
		display: block;
	}
}
