.header {
	display: flex;
	justify-content: space-between;
}

.tags {
	margin-bottom: 0.5rem;
}

.tags a[type='button'] {
	margin-right: 0.5rem;
}

.footer {
	width: 100%;
	text-align: center;
}

.footer a, .footer a:hover {
	color: var(--fill-secondary);
	text-decoration: underline;
}

@media screen and (max-width: 880px) {
	.header img {
		display: none;
	}

	.tags {
		display: flex;
		flex-direction: column;
		margin-bottom: 0;
	}

	.tags a[type='button'] {
		margin-right: 0;
		margin-bottom: 0.5rem;
	}
}
