.page {
	display: grid;
	align-items: center;
	justify-content: center;
	grid-template-columns: 1fr [content-start] 2fr [content-end] 1fr;
	height: 100%;
}

.page form {
	grid-column: content-start / content-end;
	display: flex;
	flex-direction: column;
}

@media screen and (max-width: 880px) {
	.page {
		grid-template-columns: 1fr [content-start] 4fr [content-end] 1fr;
	}
}

@media screen and (max-width: 700px) {
	.page {
		grid-template-columns: [content-start] 1fr [content-end];
	}
}
