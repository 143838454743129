.tooltip {
	--transition-timing-function: ease-in-out;
	--tooltip-width: 302px;
	--tooltip-height: 182px;
	background-image: url(../../../assets/images/tooltip.svg);
	width: var(--tooltip-width);
	height: var(--tooltip-height);
	transform-origin: 200px 100%; 
	transform: scale(1);
	opacity: 1;
	animation: wiggle-appear var(--animation-duration) var(--transition-timing-function) 0s 1;
	position: absolute;
	margin-top: -182px;
}

.tooltip :where(h1, h2, h3, h4, h5, h6) {
	font-size: 1.25rem;
	font-weight: 700;
	color: var(--text-on-neutral-dark);
	font-family: var(--font-family-body);
	letter-spacing: 0.27px;
	margin-left: 3rem;
	width: 200px;
}

.tooltip p {
	opacity: 0.75;
	color: var(--text-on-neutral-medium);
	font-family: var(--font-family-body);
	font-size: 1rem;
	font-weight: 500;
	font-style: normal;
	letter-spacing: 0.22px;
	margin: -1rem 0 0 3rem;
	width: 200px;
}

.tooltip.hidden {
	transform: scale(0);
	opacity: 0;
	animation: wiggle-disappear var(--animation-duration) var(--transition-timing-function) 0s 1;
}

@keyframes wiggle-appear {
	0% {transform: scale(0) rotate(90deg);}
  75% {transform: scale(1.1) rotate(-10deg);}
	85% {transform: scale(0.9) rotate(10deg);}
  100% {transform: scale(1) rotate(0deg);}
}

@keyframes wiggle-disappear {
	0% {transform: scale(1) rotate(0deg); opacity: 1; }
  75% {transform: scale(1.1) rotate(10deg); }
  100% {transform: scale(0) rotate(-45deg); opacity: 0; }
}