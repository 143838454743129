:root {
	/** ---------- FILL ---------- **/
	--fill-primary: RGB(20, 120, 255);
	--fill-primary-faded: RGB(20, 120, 255, 0.7);

	--fill-secondary: RGB(254, 204, 65);
	--fill-secondary-faded: RGBA(254, 204, 65, 0.6);

	--fill-neutral: RGB(255, 255, 255);

	--fill-error: RGB(255, 0, 0);
	--fill-success: RGB(0, 255, 124);

	/** ---------- BACKGROUND ---------- **/
	--background-behind-error: RGB(255, 206, 206);
	--background-behind-success: RGB(208, 255, 231);
	--background-behind-secondary: RGB(255, 243, 212);

	/** ---------- TEXT ---------- **/
	--text-on-neutral-dark: RGB(0, 0, 0);
	--text-on-neutral-medium: RGBA(0, 0, 0, 0.87);
	--text-on-neutral-light: RGBA(0, 0, 0, 0.2);

	--text-on-primary-dark: RGB(255, 255, 255);
	--text-on-primary-medium: RGBA(255, 255, 255, 0.75);
	--text-on-primary-light: RGBA(255, 255, 255, 0.2);

	--text-on-error: RGB(255, 0, 0);
	--text-on-success: RGB(0, 195, 95);
}
