:where(label i, label span).suffix {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: -3rem;
	padding: 0 1rem;
	width: 100%;
	height: 3rem;
	pointer-events: none;
}

label i.suffix {
	color: var(--text-on-neutral-light);
}

label span.suffix {
	color: var(--text-on-neutral-dark);
}

@supports selector(:has(input)) {
	label:has(input:not([type='button'], [type='reset'], [type='submit'])[aria-invalid='true']:not(:focus)) i.suffix,
	label:has(select[aria-invalid='true']:not(:focus)) i.suffix,
	label:has(input:not([type='button'], [type='reset'], [type='submit'])[aria-invalid='true']:not(:focus)) span.suffix,
	label:has(select[aria-invalid='true']:not(:focus)) span.suffix {
		color: var(--text-on-error);
	}

	label:has(input:not([type='button'], [type='reset'], [type='submit'])[aria-invalid='true']:focus) p.error,
	label:has(select[aria-invalid='true']:focus) p.error {
		display: none;
	}

	label:has(input:not([type='button'], [type='reset'], [type='submit']):disabled) i.suffix,
	label:has(select:disabled) i.suffix,
	label:has(input:not([type='button'], [type='reset'], [type='submit']):disabled) span.suffix,
	label:has(select:disabled) span.suffix {
		color: var(--text-on-primary-light);
	}
}

@supports not selector(:has(input)) {
	label[aria-invalid='true']:not(:focus-within) i.suffix,
	label[aria-invalid='true']:not(:focus-within) span.suffix {
		color: var(--text-on-error);
	}

	label[aria-invalid='true']:focus-within p.error {
		display: none;
	}

	label.disabled i.suffix,
	label.disabled span.suffix {
		color: var(--text-on-primary-light);
	}
}