.container {
	position: relative;
}

@supports selector(:has(+ label)) {
	.tooltip:has(+ label) {
		margin-top: calc((-1 * var(--tooltip-height)) + 2rem);
		margin-left: calc(110% - var(--tooltip-width));
	}
}

@supports not selector(:has(+ label)) {
	.tooltip.hasLabel {
		margin-top: calc((-1 * var(--tooltip-height)) + 2rem);
		margin-left: calc(110% - var(--tooltip-width));
	}
}