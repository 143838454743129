.page {
	display: grid;
	align-items: center;
	justify-content: center;
	grid-template-columns: 1fr [content-start] 2fr [content-end] 1fr;
	height: 100%;
}

.form {
	grid-column: content-start / content-end;
	display: flex;
	flex-direction: column;
}

/* Replace with .form { gap: 1rem } once flexbox gap is supported. */
.form h1, .form button, .form a {
	margin-bottom: 1rem;
}

.form form {
	display: flex;
	flex-direction: column;
}

.forgotButtons {
	display: flex;
	justify-content: space-between;
}

@media screen and (max-width: 880px) {
	.page {
		grid-template-columns: 1fr [content-start] 4fr [content-end] 1fr;
	}
}

@media screen and (max-width: 700px) {
	.page {
		grid-template-columns: [content-start] 1fr [content-end];
	}
}
