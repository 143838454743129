input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox']),
textarea,
select {
	appearance: none;
	background-image: none;
	border-radius: 0.5rem;
	background-color: var(--fill-neutral);
	padding: 0 1rem;
	height: 3rem;
	width: 100%;
	border: 1px solid var(--fill-neutral);
	color: black;
	transition:
		color var(--transition-duration) var(--transition-timing-function),
		border var(--transition-duration) var(--transition-timing-function),
		background-color var(--transition-duration) var(--transition-timing-function);
}

input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox'])[aria-invalid='true']:not(:focus),
select[aria-invalid='true']:not(:focus),
input[type='checkbox'][aria-invalid='true']:not(:focus) {
	background-color: var(--background-behind-error);
	border: 1px solid var(--fill-error);
	color: var(--text-on-error);
}

input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox'])[aria-invalid='true']:not(:focus)::placeholder,
select[aria-invalid='true']:not(:focus)::placeholder {
	color: var(--text-on-error);
}

input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox']):disabled,
select:disabled,
input[type='checkbox']:disabled,
input[type='checkbox']:hover:disabled {
	border: 1px solid var(--text-on-primary-medium);
	color: var(--text-on-primary-medium);
	background-color: var(--text-on-primary-light);
}

input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox']):disabled::placeholder,
select:disabled::placeholder {
	color: var(--text-on-primary-medium);
}

input[type='checkbox'] {
	appearance: none;
	margin-right: 1rem;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 4px;
	background-color: var(--fill-neutral);
	border: 1px solid transparent;
	cursor: pointer;
	transition:
		border var(--transition-duration) var(--transition-timing-function),
		background-color var(--transition-duration) var(--transition-timing-function),
}

input[type='checkbox']:hover,
input[type='checkbox']:checked {
	border: 1px solid var(--fill-secondary);
	background-color: var(--fill-secondary-faded);
}

input[type='checkbox'] {
	display: grid;
	place-content: center;
}

input[type='checkbox']::before {
	font-family: var(--font-family-icon);
	content: 'check';
	font-size: 0.8rem;
	color: var(--fill-primary);
	transform: scale(0);
	transition: transform var(--transition-duration) var(--transition-timing-function);
}

input[type='checkbox']:checked::before {
	transform: scale(1);
}

input[type='checkbox']:disabled {
	cursor: not-allowed;
}

label {
	transition: color var(--transition-duration) var(--transition-timing-function);
	white-space: pre-line;
}

label input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox']),
label select {
	margin-top: .5rem;
	display: block;
}

@supports selector(:has(input)) {
	label:has(input:not([type='button'], [type='reset'], [type='submit'])[aria-invalid='true']:not(:focus)),
	label:has(select[aria-invalid='true']:not(:focus)),
	label:has(select[aria-invalid='true']:not(:focus)):after {
		color: var(--text-on-error);
	}

	label:has(input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox'])),
	label:has(select) {
		display: inline-block;
		width: 100%;
		padding-bottom: 2rem;
	}
}

@supports not selector(:has(input)) {
	label[aria-invalid='true']:not(:focus-within),
	label[aria-invalid='true']:not(:focus-within):after {
		color: var(--text-on-error);
	}

	label {
		display: inline-block;
		width: 100%;
		padding-bottom: 2rem;
	}
}

label input:not([type='button'], [type='reset'], [type='submit'], [type='checkbox']) + p,
label select + p {
	position: absolute;
	margin: 0;
}

label p {
	margin: 0;
}

@supports selector(:has(input)) {
	label:has(input[type='checkbox']) {
		display: flex;
		align-items: center;
		padding-bottom: 1.6875rem;
	}

	label:has(input[type='checkbox'][aria-invalid='true']:not(:focus)) {
		padding-bottom: 0;
	}

	label:has(input[type='checkbox'][aria-invalid='true']:not(:focus)) p:last-of-type {
		flex-basis: 100%;
		margin: 0;
	}

	label:has(input:not([type='button'], [type='reset'], [type='submit'])[aria-invalid='true']:not(:focus)) a:not([type='button']) {
		color: var(--text-on-error);
	}

	label:has(select):after {
		content: '\e903';
		font-family: var(--font-family-icon);
		font-size: 1rem;
		color: var(--text-on-neutral-light);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: -3rem;
		padding: 0 1rem;
		width: 100%;
		height: 3rem;
		pointer-events: none;
	}

	label:has(select:disabled):after {
		color: var(--text-on-primary-medium);
	}
}

@supports not selector(:has(input)) {
	label.checkbox {
		display: flex;
		align-items: center;
		padding-bottom: 1.6875rem;
	}

	label.checkbox[aria-invalid='true']:not(:focus-within) {
		padding-bottom: 0;
	}

	label.checkbox[aria-invalid='true']:not(:focus-within) p:last-of-type {
		flex-basis: 100%;
		margin: 0;
	}

	label[aria-invalid='true']:not(:focus-within) a:not([type='button']) {
		color: var(--text-on-error);
	}

	label.select::after {
		content: 'chevron down';
		font-family: var(--font-family-icon);
		font-size: 1rem;
		color: var(--text-on-neutral-light);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: -3rem;
		padding: 0 1rem;
		width: 100%;
		height: 3rem;
		pointer-events: none;
	}

	label.select.disabled::after {
		color: var(--text-on-primary-medium);
	}
}