.container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 1.5rem 0 6.5rem 0;
}

.container.ownMargin {
	margin: 1.5rem 10vw 6.5rem 10vw;
}

/* Replace with .container { gap: 3.5rem } once flexbox gap is supported. */
.container > a:not(:last-of-type) {
	margin-right: 3.5rem;
}

.logo {
	flex: 1;
	z-index: var(--header-index);
}

@media screen and (max-width: 1280px) {
	/* Replace with .container { gap: 2rem } once flexbox gap is supported. */
	.container > a:not(:last-of-type) {
		margin-right: 2rem;
	}
}

@media screen and (max-width: 1024px) {
	.container {
		margin: 1rem 0 2rem 0;
	}

	.container a:not(.logo) {
		display: none;
	}
}
