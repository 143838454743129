label.container h2 {
	margin-top: 0;
}

label.container button {
	width: 12rem;
}

label.isLoading h2 {
	color: var(--text-on-primary-light);
	transition: color var(--transition-duration) var(--transition-timing-function);
}
