.stepper {
	background-image: linear-gradient(var(--text-on-primary-light) 50%, rgba(255,255,255,0) 0%);
	background-position: right;
	background-size: 2px 1rem;
	background-repeat: repeat-y;
}

.stepper :global(.step) {
	position: relative;
	width: calc(100% + 1.5rem);
}

.mobile {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-image: linear-gradient(to right, var(--text-on-primary-light) 50%, rgba(255,255,255,0) 0%);
	background-position: bottom;
	background-size: 1rem 2px;
	background-repeat: repeat-x;
	padding-bottom: 1rem;
}

.mobile h3 {
	text-align: right;
	margin: 0;
}

.mobile :global(.medium) {
	text-align: right;
	margin: 0;
}

.progressContainer {
	position: relative;
	width: 4rem;
	height: 4rem;
}

.progressSvg {
	position: relative;
	width: 100%;
	height: 100%;
	transform: rotate(-90deg);
}

.circle {
	--pi: 3.14159265358979;
	--stroke-width: 0.25rem;
	width: calc(100% - (2 * var(--stroke-width)));
	height: calc(100% - (2 * var(--stroke-width)));
	fill: none;
	stroke-width: var(--stroke-width);
	/* stroke-dasharray can't receive a `var()` for some reason. */
	stroke-dasharray: 188.4955592154;
	stroke-linecap: round;
	transform: translate(calc(var(--stroke-width) / 2), calc(var(--stroke-width) / 2));
}

.progressCircle {
	--progress: 0;
	--radius: calc(2rem - (var(--stroke-width) / 2));
	--circumference: calc(var(--pi) * (2 * var(--radius)));
	stroke: var(--fill-success);
	stroke-dashoffset: calc(var(--circumference) * (1 - var(--progress)));
	transition: stroke-dashoffset var(--transition-duration) var(--transition-timing-function);
}

.backgroundCircle {
	stroke: var(--text-on-primary-light);
}

.progressText {
	display: flex;
	width: 100%;
	height: 100%;
	margin-top: -100%;
	align-items: center;
	justify-content: center;
}
