.passwordContainer {
	display: flex;
	align-items: center;
	background-color: var(--fill-neutral);
	border: 1px solid var(--fill-neutral);
	border-radius: 0.5rem;
	margin-top: 0.5rem;
	transition:
		border var(--transition-duration) var(--transition-timing-function),
		background-color var(--transition-duration) var(--transition-timing-function);
}

.passwordContainer + p:global(.error) {
	position: absolute;
	margin: 0;
}

.passwordContainer input.input,
.passwordContainer input.input[aria-invalid='true'],
.passwordContainer input.input:disabled {
	background-color: transparent !important;
	border: none !important;
	margin-top: 0;
	outline: none;
}

.passwordContainer i {
	color: var(--text-on-neutral-light);
	transition: color var(--transition-duration) var(--transition-timing-function);
	width: 1.5rem;
	height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

@supports selector(:has(input)) {
	.passwordContainer:has(input.input[aria-invalid='true']:not(:focus)) {
		background-color: var(--background-behind-error);
		border: 1px solid var(--fill-error);
	}

	.passwordContainer:has(input.input:disabled) {
		background-color: var(--text-on-primary-light);
		border: 1px solid var(--text-on-primary-medium);
	}

	.passwordContainer:has(input.input[aria-invalid='true']:not(:focus)) i {
		color: var(--text-on-error);
	}
}

@supports not selector(:has(input)) {
	label[aria-invalid='true']:not(:focus-within) .passwordContainer {
		background-color: var(--background-behind-error);
		border: 1px solid var(--fill-error);
	}

	label.disabled .passwordContainer {
		background-color: var(--text-on-primary-light);
		border: 1px solid var(--text-on-primary-medium);
	}

	label[aria-invalid='true']:not(:focus-within) .passwordContainer i {
		color: var(--text-on-error);
	}
}
