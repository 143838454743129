h1 {
	margin-top: 0;
}

.page {
	--header-height: 10.8125rem;
	display: grid;
	grid-template-columns: 12rem auto;
	height: calc(100vh - var(--header-height));
	gap: 1rem;
	width: 100%;
	height: 100%;
}

.container {
	display: grid;
	grid-template-columns: 1fr [content-start] 2fr [content-end] 1fr;
	gap: 1rem;
}

.content {
	grid-column: content-start / content-end;
}

.stepperMobile {
	display: none;
}

@media screen and (max-width: 1280px) {
	.container {
		grid-template-columns: 1fr [content-start] 4fr [content-end] 1fr;
	}
}

@media screen and (max-width: 880px) {
	.page {
		grid-template-columns: auto;
		grid-template-rows: min-content;
	}

	.container {
		grid-template-columns: [content-start] 1fr [content-end];
	}

	.stepperMobile {
		display: flex;
		padding: 0 10vw 1rem 10vw;
		margin: 0 -10vw 1rem -10vw;
	}

	.stepperDesktop {
		display: none;
	}
}

@media screen and (max-width: 700px) {
	.page {
		grid-template-columns: auto;
	}
}

@media screen and (max-width: 500px) {
	.stepperMobile {
		padding: 0 1rem 1rem 1rem;
		margin: 0 -1rem 1rem -1rem;
	}
}
