:root {
	--transition-timing-function: cubic-bezier(.4, 0, .2, 1);
	--transition-duration: .28s;
	--animation-duration: .44s;
}

.initial {
	--transition-duration: 0s;
	--animation-duration: 0s;
}

@media (prefers-reduced-motion) {
	:root {
		--transition-duration: 0s;
		--animation-duration: 0s;
	}
}
