.header {
	text-align: center;
}

.header h1 {
	color: var(--fill-secondary);
	font-size: 4rem;
}


.content {
	margin-bottom: 10px;
}

.content h4 {
	margin-bottom: 10px;
}

.content p {
	margin-top: 0px;
	margin-bottom: 40px;
	text-align: center;
}

.content a {
	width: 75%;
	display: block;
	margin: auto;
	margin-bottom: 10px;
	margin-top: 10px;
}

.content button {
	width: 100%;
	font-size: 1.2rem;
}

.vibe { 
	width: 50%;
	margin: auto;
	margin-top: 40px;
}

.mailto {
	font-style: italic;
}

iframe {
	width: 100%;
    height: 750px;
}

@media screen and (max-width: 500px) {
	.header h1 {
		font-size: 5rem;
	}

	button {
		height: 50px;
	}
}