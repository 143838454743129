@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500;600;700&family=Knewave&display=swap');

@font-face {
	font-family: 'High-Five Icons';
	font-style: normal;
	font-weight: 400;
	src: url('../../assets/fonts/high-five-icons.ttf') format('truetype');
}

:root {
	--font-family-body: Dosis;
	--font-family-heading: Knewave;
	--font-family-icon: 'High-Five Icons';
}
