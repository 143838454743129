.container {
	position: relative;
}

.container a, .container button {
	width: 100%;
}

.tooltip {
	margin-left: calc(100% - (var(--tooltip-width) - 3rem));
}

.tooltip a:not([type='button']), .tooltip button[type='button'] {
	display: block;
	padding: 0;
	color: var(--fill-primary);
	text-align: left;
	transition: opacity var(--transition-duration) var(--transition-timing-function);
}

.tooltip button[type='button'] {
	margin: 0;
}

.tooltip a:not([type='button']):hover, .tooltip button[type='button'] {
	color: var(--fill-primary);
	opacity: 0.8;
}

.tooltip p.body {
	margin: 1rem 0 0 3rem;
}

.buttons {
	display: flex;
	margin-top: 1rem;
	margin-left: 3rem;
	width: 14rem;
}
