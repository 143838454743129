.page {
	background-image: var(--background-image);
	background-size: cover;
	background-repeat: repeat;

	display: flex;
	justify-content: center;
	min-height: 100vh;
	height: 100%;
}

.yellowLine::before {
	content: "";
	display: block;
	width: 100vw;
	height: 100vh;
	background-image: url(../../assets/images/yellow-line.svg);
	background-repeat: no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
}

.content {
	width: 80vw;
	max-width: 78rem;
}

@media screen and (max-width: 500px) {
	#root {
		width: calc(100vw - 2rem);
	}
}
