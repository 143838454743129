i {
	font-family: var(--font-family-icon);
	font-weight: normal;
	font-style: normal;
	font-size: 1rem;
	line-height: 1;
	letter-spacing: normal;
	text-decoration: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}
