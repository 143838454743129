section.container {
	overflow-y: visible;
}

section.container + h1,
section.container + h2,
section.container + h3 {
	margin-top: 0;
}

section.container footer {
	display: flex;
	justify-content: space-between;
	margin: 9rem 0 2rem;
}

@media screen and (max-width: 1024px) {
	section.container footer {
		flex-direction: column-reverse;
	}

	/* Replace with section.container footer { gap: 1rem } once flexbox gap is supported. */
	section.container footer > :last-child {
		margin-bottom: 1rem;
	}
}
